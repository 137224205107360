import { createMuiTheme } from '@material-ui/core/styles';

import uiTheme from './theme';

//
// CUSTOM THEME BASE
// --------------------------------------------------

const theme = createMuiTheme({
  // custom colors via material's color tool:
  // https://goo.gl/T92kNk
  palette: {
    background: {
      default: '#fff !important',
    },
    primary: uiTheme.primary,
    secondary: uiTheme.secondary,
    error: uiTheme.error,
  },

  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      'Open Sans',
      'sans-serif',
    ].join(','),
    fontSize: 12,
    useNextVariants: true,
  },
  // other global properties
  shadows: Array(25).fill('none'),
});


//
// SPECIFIC UI COMPONENT CUSTOMIZATIONS
// --------------------------------------------------

export default theme;
theme.overrides.MuiTypography = {
  root: {
    fontSize: '16px',
  },
  body2: {
    fontSize: '16px',
  },
};

theme.overrides.MuiFormLabel = {
  root: {
    fontSize: '12px',
  },
  filled: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
};


theme.overrides.MuiExpansionPanel = {
  root: {
    backgroundColor: 'transparent',
  },
  expanded: {
    margin: 'auto',
  },
};

theme.overrides.MuiExpansionPanelSummary = {
  root: {
    borderBottom: '1px solid rgba(245, 245, 245, 0.68)',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&:last-child': {
      padding: 0,
    },
    '&$expanded': {
      margin: '12px 0',
    },
  },
};

theme.overrides.MuiExpansionPanelDetails = {
  root: {
    padding: 0,
  },
};

theme.overrides.MuiPaper = {
  root: {
    backgroundColor: 'transparent',
  },
};

theme.overrides.MuiDialog = {
  root: {
    backgroundColor: 'rgb(0,0,0,0.6)',
  },
};
