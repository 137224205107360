import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';


// reducers
import project from './project/reducer';

const globalReducer = history => combineReducers({
  router: connectRouter(history),
  project,
});

export default globalReducer;
