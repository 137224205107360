import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import faStar from '@fortawesome/fontawesome-free-solid/faStar';
import faStarReg from '@fortawesome/fontawesome-free-regular/faStar';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import swal from 'sweetalert2';
import { Button } from '@material-ui/core';
import { Wrapper } from './styles';
import Notification from '../Notification';
// import SliderComponent from '../../components/slider/index';
import {
  get, rate, setStars, setPrice,
} from '../../redux/project/actions';
import Header from '../../components/header';
import Footer from '../../components/footer';
import SliderComponent from '../../components/slider';


// eslint-disable-next-line
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { dispatch, match: { params: { idProject, token } } } = this.props;
    dispatch(get(idProject, token));
  }

  onClickStar = (number, idVipOpinion) => {
    const { dispatch } = this.props;
    dispatch(setStars(number, idVipOpinion));
  }

  onChange = (text, idVipOpinion) => {
    const { dispatch } = this.props;
    dispatch(setPrice(text, idVipOpinion));
  }

  onSubmitValuation = () => {
    const {
      dispatch, match: { params: { idProject, token } }, project: { VipOpinions: vipOpinions },
    } = this.props;
    dispatch(rate({
      vipOpinions, idProject, token,
    }));
  }

  onClick = () => {
    const { project } = this.props;
    const emptyOpinion = project.VipOpinions.find(opinion => !opinion.stars || !opinion.price);
    const badPriceOpinion = project.VipOpinions.find(opinion => (isNaN(Number(opinion.price)) || Number(opinion.price) < 0)); // eslint-disable-line
    if (emptyOpinion) {
      swal.fire({
        title: 'Datos incompletos',
        text: 'Debes rellenar los dos campos para enviar la valoración',
        type: 'warning',
        confirmButtonColor: '#000000',
        cancelButtonColor: '#d33',
        confirmButtonText: 'De acuerdo',
      });
    } else if (badPriceOpinion) { // eslint-disable-line
      swal.fire({
        title: 'Precio no válido',
        text: 'Debes introducir un número, en euros, en el campo de precio para valorar correctamente.',
        type: 'warning',
        confirmButtonColor: '#000000',
        cancelButtonColor: '#d33',
        confirmButtonText: 'De acuerdo',
      });
    } else {
      this.onSubmitValuation();
    }
  };

  render() {
    const {
      project,
      loading,
    } = this.props;
    if (!project || !project.idProject) return <Notification project={project} loading={loading} />;

    const proposals = project.ProjectProposal.DesignerFinalProposals.map((finalProposal, index) => {
      const vipOpinion = project.VipOpinions
        .find(vipOp => vipOp.idDesignerFinalProposal === finalProposal.idDesignerFinalProposal);
      let displayImages = [];
      if (finalProposal.ProjectProposalFinalRenders.length) {
        finalProposal.ProjectProposalFinalRenders.map((picture) => {
          if (picture.url.indexOf('jpg') !== -1 || picture.url.indexOf('jpeg') !== -1 || picture.url.indexOf('png') !== -1) {
            displayImages.push(picture);
          }
          return null;
        });
      }
      if (displayImages.length < 1) {
        displayImages.push({ url: 'http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder-800x423.gif' });
        displayImages.push({ url: 'http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder-800x423.gif' });
      } else if (displayImages.length === 1) {
        displayImages = [...displayImages, ...displayImages];
      }
      let { stars } = vipOpinion;
      if (stars === null) stars = 0;
      return (
        <div className="picture-and-price" key={finalProposal.idDesignerFinalProposal}>
          <h3>Propuesta {index + 1}</h3>
          <div className="slider-container">
            <SliderComponent>
              {displayImages.length
                ? displayImages.map(picture => (
                  <div className="image-container" key={picture.idProjectProposalFinalRender}>
                    <img src={picture.url} alt="slide" className="img" />
                  </div>
                ))
                : null
              }
            </SliderComponent>
          </div>
          {/* <div className="img" style={displayImages.length ? { backgroundImage: `url(${displayImages[0].url})` } : { backgroundImage: 'url(http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder-800x423.gif)' }} /> */}
          <div className="picture-footer">
            <div className="picture-and-price-footer-part left">
              ¿Cuánto pagarías tras la reforma?
              <div className="price-form">
                <input type="text" className="textField" color="white" onChange={e => this.onChange(e.target.value, vipOpinion.idVipOpinion)} />€
              </div>
            </div>
            <div className="picture-and-price-footer-part">
              <h3>¿Qué puntuación le darías?</h3>
              <div className="stars-score">
                <FontAwesomeIcon
                  icon={stars >= 1 ? faStar : faStarReg}
                  onClick={() => this.onClickStar(1, vipOpinion.idVipOpinion)}
                  color={stars >= 1 ? 'rgb(232, 201, 48)' : 'gray'}
                />
                <FontAwesomeIcon
                  icon={stars >= 2 ? faStar : faStarReg}
                  onClick={() => this.onClickStar(2, vipOpinion.idVipOpinion)}
                  color={stars >= 2 ? 'rgb(232, 201, 48)' : 'gray'}
                />
                <FontAwesomeIcon
                  icon={stars >= 3 ? faStar : faStarReg}
                  onClick={() => this.onClickStar(3, vipOpinion.idVipOpinion)}
                  color={stars >= 3 ? 'rgb(232, 201, 48)' : 'gray'}
                />
                <FontAwesomeIcon
                  icon={stars >= 4 ? faStar : faStarReg}
                  onClick={() => this.onClickStar(4, vipOpinion.idVipOpinion)}
                  color={stars >= 4 ? 'rgb(232, 201, 48)' : 'gray'}
                />
                <FontAwesomeIcon
                  icon={stars >= 5 ? faStar : faStarReg}
                  onClick={() => this.onClickStar(5, vipOpinion.idVipOpinion)}
                  color={stars >= 5 ? 'rgb(232, 201, 48)' : 'gray'}
                />
              </div>
            </div>
          </div>
        </div>
      );
    });
    return (
      <Wrapper>
        <Header project={project} />
        <div className="before">
          <div className="picture-and-price">
            <h2>Antes</h2>
            <div className="slider-container">
              <SliderComponent>
                {project.ProjectPictures.length
                  ? project.ProjectPictures.map(picture => (
                    <div className="image-container" key={picture.url}>
                      <img src={picture.url} alt="slide" className="img" />
                    </div>
                  ))
                  : null
                }
              </SliderComponent>
            </div>
            <div className="picture-and-price-footer">
              Precio actual de esta habitación:
              <span className="price">{project.beforePrice} €</span>
            </div>
          </div>
        </div>
        <div className="pictures">
          <h2>Después</h2>
          <div className="proposals">
            {proposals}
          </div>
          <Button className="button" onClick={() => this.onClick()}>Valorar</Button>
          <div className="thanks">¡Muchas gracias!</div>
        </div>
        <Footer />
      </Wrapper>
    );
  }
}

Home.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default connect(state => ({
  project: state.project.project,
  loading: state.project.loading,
}))(Home);
