import styled from 'styled-components';


export const Wrapper = styled.div`
  background: #FAFAFA;
  h1 {
    font-weight: bold;
    font-size: 28px;
    font-family: 'Open Sans';
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }

  h2 {
    color:black;
    font-family: Playfair Display;
    font-size: 40px;
    text-transform: uppercase;
  }

  .image-container {
    display: flex !important;
    justify-content: center;
  }

  .slider-container {
    width: 700px;
    @media (max-width: 1450px) {
      width: 600px;
    }
  }

  .before {
    color:black;
    padding: 50px 0;
    .picture-and-price {
      display: flex;
      flex-direction: column;
      align-items: center;
      color:black;
      font-weight: lighter;
      text-transform: uppercase;
      font-size: 20px;
      font-family: 'Open Sans';
      .img {
        position: relative;
        float: left;
        height: 350px;
        width: 550px;
        @media (max-width: 1450px) {
          height: 300px;
          width: 450px;
        }
        background-position: 50% 50%;
        background-repeat:   no-repeat;
        background-size:     cover;
      }
      &-footer {
        margin-top: 30px;
        font-size: 14px;
        text-transform: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        .price {
          font-size: 30px;
          font-weight: 700;
        }
      }
    }
  }

  .pictures {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 50px 0;
    h2 {
      margin-top: 20px;
      margin-bottom: 40px;
    }
    .button {
      border:black 1px solid;
      border-radius: 5px;
      color:black;
      height: 40px;
      width: 20%;
      font-size: 15px;
      padding: 5px;
    }
    .button:hover {
      color: white;
      background: #313131;
    }
    .thanks {
      margin: 50px 0;
      font-family: Playfair Display;
      font-size: 40px;
    }
    .proposals {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      .picture-and-price {
        display: flex;
        flex-direction: column;
        align-items: center;
        color:black;
        font-weight: lighter;
        text-transform: uppercase;
        font-size: 20px;
        font-family: 'Open Sans';
        margin-bottom: 20px;
        width: 50%;
        .picture-footer {
          display: flex;
          justify-content: space-between;
          width: 550px;
          @media (max-width: 1450px) {
            width: 450px;
          }
          margin: 30px 0;
        }
        .stars-score {
          display: flex;
          align-items: center;
          color:black;
          font-weight: bold;
          font-size: 28px;
          font-family: 'Open Sans';
          margin-bottom: 30px;
          cursor: pointer;
          .star {
            color: black;
          }
        }
        .img {
          position: relative;
          float: left;
          height: 350px;
          width: 550px;
          @media (max-width: 1450px) {
            height: 300px;
            width: 450px;
          }
          background-position: 50% 50%;
          background-repeat:   no-repeat;
          background-size:     cover;
        }
        &-footer-part {
          font-size: 14px;
          text-transform: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100px;
          h3 {
            color:black;
            font-weight: lighter;
            font-family: 'Open Sans';
            font-size: 14px;
            text-transform: none;
            margin-top: 0;
          }
          .price {
            font-size: 30px;
            font-weight: 700;
            &-form {
              display: flex;
              align-items: flex-end;
              margin-top: 5px;
              font-size: 24px;
              .textField {
                font-size: 30px;
                font-weight: 700;
                font-family: 'Open Sans';
                margin-right: 15px;
                color:black;
                background: transparent;
                border: 0;
                border-bottom: 1px solid;
                width: 30%;
              }
              .textField:focus {
                outline: none;
                border-bottom: 2px solid;
              }
            }
          }
        }
        .left {
          align-items: flex-start;
          max-width: 50%;
        }
      }
    }
  }

`;
