
import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: #313131;
    height: 100px;
    width: 100%;
    padding: 20px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Open Sans';
    font-size: 12px;
    color: white;
    .left {
        display: flex;
        flex-direction: column;
        p {
            margin: 3px 0;
        }
        a {
            color: white;
            margin-right: 5px;
        }
    }
    .right {
        img {
            width: 100px;
        }
    }
`;
