import { all } from 'redux-saga/effects';

// sagas
import projectSaga from './project/sagas';

function* rootSaga() {
  yield all([
    ...projectSaga,
  ]);
}

export default rootSaga;
