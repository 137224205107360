import {
  GET,
  GET_SUCCEEDED,
  PETITION_FAILED,
  RESET,
  RATE,
  RATE_SUCCEEDED,
  SET_STARS,
  SET_PRICE,
} from './action-types';

export const get = (idProject, token) => ({
  type: GET, idProject, token,
});
export const getSucceeded = project => ({
  type: GET_SUCCEEDED, project,
});
export const rate = ({
  vipOpinions, idProject, token,
}) => ({
  type: RATE, vipOpinions, idProject, token,
});
export const rateSucceeded = () => ({
  type: RATE_SUCCEEDED,
});
export const petitionFailed = error => ({ type: PETITION_FAILED, error });
export const reset = idProject => ({
  type: RESET, idProject,
});

export const setStars = (num, idVipOpinion) => ({ type: SET_STARS, num, idVipOpinion });
export const setPrice = (text, idVipOpinion) => ({ type: SET_PRICE, text, idVipOpinion });
