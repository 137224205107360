import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from '../Home/styles';
import Header from '../../components/header';

const Notification = ({ project, loading }) => (
  <Wrapper>
    <Header project={project} loading={loading} />
  </Wrapper>
);

Notification.propTypes = {
  project: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default Notification;
