
import styled from 'styled-components';

export const Wrapper = styled.div`
    background: #ffffff;
    display: flex;
    flex-direction: column;
    .gray-bar {
        background-color: #313131;
        height: 100px;
        width: 100%;
        padding-left: 100px;
        display: flex;
        align-items: center;
        img {
            width: 100px;
        }
    }
    .white-section {
        height: 300px;
        background: white;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        padding: 0 15%;
        img {
            width: 250px;
            margin-right: 50px;
        }
        .right-texts {
            margin-left: 50px;
            max-width: 50%;
            h1 {
                font-weight: bold;
                font-size: 30px;
                font-family: Playfair Display;
            }
            p {
                font-size: 18px;
                font-family: 'Open Sans';
                font-weight: lighter;
            }
        }

    }
`;

export const Logo = styled.div`
img { width: 120px; }
@media (max-width: 1323px) {
  img { width: 70px; }
}
`;
