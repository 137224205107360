import React from 'react';
import { Wrapper } from './index.styles';
import Tripadvisor from '../../assets/images/tripadvisor.svg';


const Footer = () => (
  <Wrapper>
    <div className="left">
      <p>© 2019 BARCELO HOTEL GROUP | BARCELO.COM</p>
      <p>
        <a href="https://www.barcelo.com/es/informacion-general/aviso-legal">Términos legales</a>
        <a href="https://www.barcelo.com/es/informacion-general/politica-privacidad">Política de privacidad</a>
        <a href="https://www.barcelo.com/es/informacion-general/">Información general</a>
      </p>
      <p>PROD-MULTI-01</p>
    </div>
    <div className="right">
      Ahora con reseñas de usuarios de <img src={Tripadvisor} alt="TripAdvisor" />
    </div>
  </Wrapper>
);

export default Footer;
