import React from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Wrapper } from './App.styles';
import routes from '../routes';

import { reset } from '../redux/project/actions';

class App extends React.Component {
  constructor(props) {
    super(props);
    const { dispatch } = props;
    dispatch(reset());
  }

  render() {
    return (
      <Wrapper>
        <div className="routes">
          {routes.map(r => <Route key={r.path} exact {...r} />)}
        </div>
      </Wrapper>
    );
  }
}

App.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default withRouter(connect(
  state => ({
    project: state.project.project,
  }),
)(App));
