import defaultConfig from './default';
import development from './development';
import staging from './staging';
import production from './production';
import test from './test';

const { NODE_ENV } = process.env;
const env = NODE_ENV;
// const hostname = (window && window.location && window.location.hostname) || '';
// const env = hostname.endsWith('dev-roi.io') ? 'test' : NODE_ENV;

const envConfings = {
  development,
  staging,
  production,
  test,
};

const currentEnvConfing = envConfings[
  env in envConfings ? env : 'development'
];

// the ultimate config
export default {
  defaultConfig,
  ...currentEnvConfing,
};
