import styled from 'styled-components';

export const Wrapper = styled.div`
    background: #ffffff;
`;

export const Logo = styled.div`
  background-color: rgb(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  position: fixed;
  right: 0;
  bottom: 70px;
  img { width: 120px; }
  @media (max-width: 1323px) {
    img { width: 70px; }
  }
`;
