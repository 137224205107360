import { call, put, takeEvery } from 'redux-saga/effects';
import api from '../../barcelo-api';

import { GET, RATE } from './action-types';
import {
  get, getSucceeded, petitionFailed, rateSucceeded,
} from './actions';


function* reqGet(action) {
  const { idProject, token } = action;
  try {
    const project = yield call(api.project.getProjectForm,
      { idProject, token });
    yield put(getSucceeded(project));
  } catch (error) {
    yield put(petitionFailed(error));
  }
}


function* reqRate(action) {
  const {
    idProject, token, vipOpinions,
  } = action;
  try {
    yield call(api.project.valueProjectForm,
      {
        vipOpinions, idProject, token,
      });
    yield put(get(idProject, token));
    yield put(rateSucceeded());
  } catch (error) {
    yield put(petitionFailed(error));
  }
}


export default [
  takeEvery(GET, reqGet),
  takeEvery(RATE, reqRate),
];
