import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Logo } from './index.styles';
import barceloHotelGroup from '../../assets/images/barceloHotelGroup.svg';
import logoBlack from '../../assets/images/logo.png';


const Header = ({ project, loading }) => {
  let content = (
    <Wrapper>
      <div className="gray-bar">
        <img src={barceloHotelGroup} alt="Project Design Hub Logo" />
      </div>
      <div className="white-section">
        <Logo className="logo">
          <img src={logoBlack} alt="Project Design Hub Logo" />
        </Logo>
        <div className="right-texts">
          <h1>¡Necesitamos tu opinión!</h1>
          <p>Hola, hemos pensado que podrías ayudarnos dándonos tu valoración con
            el proyecto de reforma para <strong>{project.name}</strong>.
            Nos importa tu opinión y nos
            gustaría que valoraras la propuesta, sólo te llevará 1 minuto. ¡Muchas gracias!
          </p>
        </div>
      </div>
    </Wrapper>
  );
  if (loading) {
    content = (
      <Wrapper>
        <div className="gray-bar">
          <img src={barceloHotelGroup} alt="Project Design Hub Logo" />
        </div>
        <div className="white-section">
          <Logo className="logo">
            <img src={logoBlack} alt="Project Design Hub Logo" />
          </Logo>
          <div className="right-texts">
            <h1>Cargando...</h1>
          </div>
        </div>
      </Wrapper>
    );
  } else if (!project.idProject) {
    content = (
      <Wrapper>
        <div className="gray-bar">
          <img src={barceloHotelGroup} alt="Project Design Hub Logo" />
        </div>
        <div className="white-section">
          <Logo className="logo">
            <img src={logoBlack} alt="Project Design Hub Logo" />
          </Logo>
          <div className="right-texts">
            <h1>Ha habido un error</h1>
            <p>Hola, es posible que ya hayas votado para este proyecto.
              Esta es una plataforma de voto único.
              Si no has votado todavía y sin embargo ves este mensaje
              eso significa que están habiendo cambios
              con el proyecto a valorar. Gracias por ayudarnos a mejorar
              y disculpa molestias.
            </p>
          </div>
        </div>
      </Wrapper>
    );
  }
  return content;
};

Header.propTypes = {
  project: PropTypes.object.isRequired,
};

export default Header;
