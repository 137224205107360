/* eslint-disable */
import Slider from 'react-slick';
import React from 'react';
import ArrowRight from './keyboard-right-arrow-button.svg';
import ArrowLeft from './left-arrow-key.svg';
import { Wrapper } from './index.styles.js';

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props; //eslint-disable-line
  return (
      <img src={ArrowRight} className='arrow right' onClick={onClick} />
  );
}

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props; //eslint-disable-line
  return (
      <img src={ArrowLeft} className='arrow left' onClick={onClick} />
  );
}


const settingsDesktop = {
  nextArrow: <SampleNextArrow className='arrow_container' />,
  prevArrow: <SamplePrevArrow className='arrow_contaner' />,
  slidesToShow: 1,
  slidesToScroll: 1
};


const SliderComponent = ({ children, arrows = true, dots = false }) => (
  <Wrapper>
    <Slider {...settingsDesktop} className='wrapper'>
      {children}
    </Slider>
  </Wrapper>
)

export default SliderComponent