export default {
  primary: {
    main: '#000000',
    light: '#242424',
    dark: '#242424',
  },
  secondary: {
    main: 'rgb(255,255,255,0.8)',
    light: 'rgb(255,255,255,0.8)',
    dark: 'rgb(255,255,255,0.8)',
  },
  error: {
    main: '#FF0000',
  },
};
