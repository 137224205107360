import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    padding: 0 5px;
    position: relative;
    .wrapper {
      display: flex;
    align-items: center;
    }
    .arrow {
        width: 40px;
        height: 40px;
        margin: 10px;
        position: absolute;
        z-index: 10;
        top: calc(50% - 20px);
        cursor: pointer;
      }
    .right {
      right: 0;
    }
    .left {
      left: 0;
    }
`;
