import swal from 'sweetalert2';
import {
  GET,
  GET_SUCCEEDED,
  PETITION_FAILED,
  RESET,
  RATE,
  RATE_SUCCEEDED,
  SET_STARS,
  SET_PRICE,
} from './action-types';
// import utils from '../utils';


const initialState = {
  project: {},
  loading: false,
};

const projectReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case RATE:
    case GET: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SUCCEEDED: {
      const { project } = action;
      return {
        ...state,
        project,
        loading: false,
      };
    }
    case PETITION_FAILED: {
      swal.fire({
        title: 'Valoración fallida',
        text: 'La valoración no se ha guardado. Disculpe las molestias e inténtelo más tarde.',
        type: 'error',
        confirmButtonColor: '#000000',
        cancelButtonColor: '#d33',
        confirmButtonText: 'De acuerdo',
      });
      return {
        ...state,
        loading: false,
      };
    }

    case RATE_SUCCEEDED: {
      swal.fire({
        title: 'Valoración enviada',
        text: 'La valoración se ha guardado con éxito. Gracias por su colaboración.',
        type: 'success',
        confirmButtonColor: '#000000',
        cancelButtonColor: '#d33',
        confirmButtonText: 'De acuerdo',
      });
      window.location = 'https://www.barcelo.com/es/';
      return {
        ...state,
        loading: false,
      };
    }

    case RESET: {
      return {
        ...state,
        project: { ready: true },
      };
    }

    case SET_STARS: {
      const { num, idVipOpinion } = action;
      const { VipOpinions } = state.project;
      let i;
      const newVipOpinion = VipOpinions.find((opinion, index) => {
        i = index;
        return opinion.idVipOpinion == idVipOpinion; // eslint-disable-line
      });
      VipOpinions.splice(i, 1);
      newVipOpinion.stars = num;
      return {
        ...state,
        project: {
          ...state.project,
          VipOpinions: [
            ...VipOpinions,
            newVipOpinion,
          ],
        },
      };
    }

    case SET_PRICE: {
      const { text, idVipOpinion } = action;
      const { VipOpinions } = state.project;
      let i;
      const newVipOpinion = VipOpinions.find((opinion, index) => {
        i = index;
        return opinion.idVipOpinion == idVipOpinion; // eslint-disable-line
      });
      VipOpinions.splice(i, 1);
      newVipOpinion.price = text;
      return {
        ...state,
        project: {
          ...state.project,
          VipOpinions: [
            ...VipOpinions,
            newVipOpinion,
          ],
        },
      };
    }

    default:
      return state;
  }
};

export default projectReducer;
